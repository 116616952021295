import React, { useState } from "react";
import YouTube from "react-youtube";
import { Fade } from "react-awesome-reveal";

import VideoModal from "../VideoModal/VideoModal";

import videoOverlayBackground from "./assets/images/video-thumbnail@2x.jpg";
import arrowImage from "./assets/images/arrow-circled.svg";

export default function AboutUs({
  image,
  tag,
  heading,
  subheading,
  videotext,
}) {
  const videoId = image.split("?")[0].split("/").pop();
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  return (
    <div className="header">
      <div className="details">
        <div className="content">
          <h1>{heading}</h1>
          <p>{subheading}</p>
        </div>
      </div>
      <div className="videosection">
        <div className="content">
          <div className="video-container">
            <VideoModal
              youtubeUrl={image}
              show={videoModalVisible}
              onHide={() => setVideoModalVisible(false)}
            />
            <div
              className="video-overlay"
              style={{ backgroundImage: `url(${videoOverlayBackground})` }}
            >
              <button
                type="button"
                className="video-overlay-button stretched-link"
                onClick={() => setVideoModalVisible(true)}
              >
                <img src={arrowImage} alt="play video - watch her story" /> Watch her story
              </button>
            </div>
          </div>
          <Fade direction="top">
            <img className="tag" src={tag} alt="heart icon" />
            <h2>{videotext}</h2>
          </Fade>
        </div>
      </div>
    </div>
  );
}
