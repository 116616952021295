import React from "react";
import { Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";

export default function JoinTeam({ heading, detail, img }) {
  return (
    <div className="Join">
      <div className="content">
        <Col xs={12} sm={6}>
          <Fade direction="top">
            <h4>{heading}</h4>

            <p>{detail}</p>
            <a href="">
              <a href="mailto:alice@medanswers.com">
                {" "}
                <button>Message Us</button>
              </a>
            </a>
          </Fade>
        </Col>
      </div>
      <Fade direction="right" className="join-image">
        <img src={img} alt="person laying blocks" />
      </Fade>
    </div>
  );
}
