import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import Slider from "react-slick";
export default function Crew({ tagline, heading, members }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    variableWidth: true,
    swipeToSlide: true,
    waitForAnimate: false,
  };

  return (
    <div className="Crew">
      <div className="content">
        <h3>{tagline}</h3>
        <p>{heading}</p>
        <Slider {...settings}>
          {members.map(x => {
            return (
              <div className="eachitem">
                <img src={x.image} alt="crew member picture" />
                <h5>{x.name}</h5>
                <h6>{x.description}</h6>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
